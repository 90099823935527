import { useState } from "react";
import Modal from "react-modal";
import useModal from "../../../hooks/useModal";
import MenuToPDF from "./MenuToPDF";
import { pdf } from "@react-pdf/renderer";
import { Flex, Button, Select, Switch, Text } from "@chakra-ui/react";
import useAllergenes from "../../../hooks/useAllergenes";
import { useMenuContext } from "../../../contexts/MenuContext";

Modal.setAppElement("#root");

const PdfSettingsModal = ({ isOpen, onClose, categories, selectedRestaurantId, itemsByCategory, menuItems, currentMainCategory, mainLanguage, settings, selectedLanguage, setSelectedLanguage }) => {
  const { customStyles } = useModal();
  
  const allLanguages = settings.language.concat(settings.secondaryLanguages);
  const [showDescriptions, setShowDescriptions] = useState(true);
  const [showAllergens, setShowAllergens] = useState(false);
  const allergenes = useAllergenes();

  const handleClose = () => {
    onClose();
    setShowAllergens(false);
    setShowDescriptions(true);
  };


  
  const openMenuToPdf = async () => {
    const blob = await pdf(
      <MenuToPDF
        selectedRestaurantId={selectedRestaurantId}
        categories={categories}
        itemsByCategory={itemsByCategory}
        menuItems={menuItems}
        settings={settings}
        mainLanguage={mainLanguage}
        selectedLanguage={selectedLanguage}
        currentMainCategory={currentMainCategory}
        showAllergens={showAllergens}
        showDescriptions={showDescriptions}
        getAllergenNames={getAllergenNames}
        getTagsNames={getTagsNames}
      />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    onClose();
    setShowAllergens(false);
    setShowDescriptions(true);
  };

  const getAllergenNames = (itemAllergens) => {
    console.log("Vérification de itemAllergens dans getAllergenNames:", itemAllergens);
  
    if (!Array.isArray(itemAllergens) || itemAllergens.length === 0 || allergenes.length === 0) {
      console.log("itemAllergens est vide ou allergenes non chargés");
      return null;  // Retourner null si aucun allergène à afficher
    }
  
    const allergenNames = itemAllergens
      .map((allergenId) => {
        const cleanedAllergenId = allergenId.trim();
        const allergene = allergenes.find((a) => a.id === cleanedAllergenId);
  
        if (allergene) {
          const allergenName = allergene[`name_${selectedLanguage}`] || allergene[`name_${mainLanguage}`];
          if (!allergenName) {
            console.log(`Aucun nom trouvé pour l'allergène ID ${cleanedAllergenId}, allergène mal formé.`);
            return null;  // Retourner null si le nom n'est pas trouvé
          }
  
          return {
            name: allergenName,  // Le nom de l'allergène
            order: allergene.order  // Le champ `order` de la base de données
          };
        }
  
        console.log(`Aucun allergène trouvé pour l'ID : ${cleanedAllergenId}`);
        return null;  // Retourner null si l'allergène n'est pas trouvé
      })
      .filter(Boolean)  // Filtrer les valeurs nulles
      .sort((a, b) => a.order - b.order)  // Trier par le champ `order`
      .map((allergene) => allergene.name)  // Ne conserver que les noms après le tri
      .join(" | ");  // Concaténer les noms avec un séparateur
  
    return allergenNames || null;  // Retourner null si aucun allergène valide n'est trouvé
  };
  
  
    const tags = useMenuContext().tags;

    const getTagsNames = (itemTags) => {
      if (!itemTags || tags.length === 0) {
        console.log("Aucun tags trouvés ou collection de tags vide");
        return ''; // Si pas de tags ou tags non chargés
      }
  
      const tagsNames = itemTags
        .map((itemTag) => {
          const cleanedTagValue = itemTag.value.trim(); 
          const tag = tags.find((a) => a.id === cleanedTagValue); 
  
          if (tag) {
            const tagName = tag[`name_${selectedLanguage}`] || tag[`name_${mainLanguage}`];
            return tagName || cleanedTagValue;
          }
          return cleanedTagValue;
        })
        .join(" ");
      return tagsNames;
    };
  
  
  

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className={"modal"}
      style={customStyles}>
      <Flex className="modal-content" w={"640px"}>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
            <div className="modalTitle">Generate menu in .pdf</div>
            <div className="actionButtons"></div>
          </div>

          <div className="modalInner">
            <Flex direction="column" gap={4}>
            
            <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "50%", mobile: "100%" }}>
          <Text fontWeight={600} color={"var(--heading)"}>Language</Text>
          <Text maxW={"85%"} fontSize={14}>Select the language of the menu.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1} justifyContent={"flex-start"}>
                <Select
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}>
                  {allLanguages.map((lang, index) => (
                    <option key={index} value={lang.value}>
                      {lang.label}
                    </option>
                  ))}
                </Select>
        </Flex>
      </Flex>

      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "50%", mobile: "100%" }}>
          <Text fontWeight={600} color={"var(--heading)"}>Display descriptions</Text>
          <Text maxW={"85%"} fontSize={14}>Activate if you want to display descriptions.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1} justifyContent={"flex-start"}>
        <Switch
                id="descriptions-switch"
                isChecked={showDescriptions} 
                onChange={(e) => setShowDescriptions(e.target.checked)}
                />
        </Flex>
      </Flex>

      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "50%", mobile: "100%" }}>
          <Text fontWeight={600} color={"var(--heading)"}>Display allergens</Text>
          <Text maxW={"85%"} fontSize={14}>Activate if you want to display allergens.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1} justifyContent={"flex-start"}>
             <Switch
                id="allergenes-switch"
                isChecked={showAllergens} 
                onChange={(e) => setShowAllergens(e.target.checked)}
                />
        </Flex>
      </Flex>

           
              
             

          

            
                <Button size="lg" className="btn primary" onClick={openMenuToPdf}>
                  Generate pdf
                </Button>
            </Flex>
          </div>
        </form>
      </Flex>
    </Modal>
  );
};

export default PdfSettingsModal;
