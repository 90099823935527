import { useAppContext } from "../contexts/AppContext";
import { Flex, Text, Button, Link } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { ReceiptText, TicketPercent, ExternalLink } from "lucide-react";
function ShopLayout({ children }) {
  const { selectedRestaurantId, settings, menuAdminBarZIndex } = useAppContext();

  return (
    <>
      {settings.modules?.shop === true ? (
        <>
        <Flex
        justifyContent="space-between"
        direction="row"
        w="100%"
        alignItems="center"
        position="sticky"
        top="0"
        bg={{ desktop: "white", mobile: "var(--grey100)" }}
        px={{ desktop: 12, mobile: 4 }}
        maxWidth="100vw"
        borderBottom="1px solid var(--borders)"
        style={{ zIndex: menuAdminBarZIndex }}
      >
        <Flex className="tabLinks">
        <NavLink
          to={`/${selectedRestaurantId}/shop`}
          end
          className="tabLink"
          style={({ isActive }) => ({
            color: isActive ? "var(--brand)" : "var(--grey700)",
          })}>
          {({ isActive }) => (
            <Flex alignItems={"center"} gap={1}>
              <ReceiptText
                size={16}
                color={isActive ? "var(--brand)" : "var(--grey500)"}
              />
              <Text color={isActive ? "var(--brand)" : "var(--grey500)"} _hover={{ color: "var(--grey700)"}}>
                Orders
              </Text>
            </Flex>
          )}
        </NavLink>
        <NavLink
          to={`/${selectedRestaurantId}/shop/coupons`}
          className="tabLink"
          style={({ isActive }) => ({
            color: isActive ? "var(--brand)" : "var(--grey700)",
          })}>
          {({ isActive }) => (
            <Flex alignItems={"center"} gap={1}>
              <TicketPercent
                size={16}
                color={isActive ? "var(--brand)" : "var(--grey500)"}
              />
              <Text color={isActive ? "var(--brand)" : "var(--grey500)"} _hover={{ color: "var(--grey700)"}}>
                Coupons
              </Text>
            </Flex>
          )}
        </NavLink>
        
        
        
        </Flex>

        <Button variant="link" as={Link} href={`https://gudden.app/${selectedRestaurantId}/shop`} 
          target="_blank" gap={1} size={"sm"} _hover={{ color: "var(--grey700)" }} fontWeight={500}>
          <ExternalLink size={16} color="var(--grey500)"/>
          View shop
        </Button>
      </Flex>

      <Flex
      direction="column"
      padding={{ desktop: 12, mobile: 8 }}
      pb={{ desktop: 8, mobile: '172px' }} 
      flex={1}
      gap={8}
      w={"100%"}
      maxW="1320px">

        <Flex direction="column" gap={8} flex={1}>
         
         
          {children}
        
        
        
        </Flex>
        </Flex>

        </>
      ) : null}
    </>
  );
}
export default ShopLayout;
